export const teamMates
 = [
  {
    name: "Aditya Patel",
    image: process.env.PUBLIC_URL + "/images/Adi.png",
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Amanda Pfiel",
    image: process.env.PUBLIC_URL + '/images/Amanda_2.png',
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Alexia Leclercq",
    image: process.env.PUBLIC_URL + "/images/alexia.png",
    title: "Staff",
    bio: ""
  },
  {
    name: "Alka Tiwari",
    image: process.env.PUBLIC_URL + '/images/Alka.png',
    title: "Postdoctoral Researcher",
    bio: "I am a postdoc, working as a part of The Extreme weather and Urban Sustainability (TExUS) Lab. I have received my PhD from Purdue University advised by Dr. Keith Cherkauer and mentored by Dr. Frank Marks, Dr. Wen-wen Tung and Dr. Dev Niyogi as my committee members. I was advised by Dr. Shivam Tripathi for my Master's in Civil Engineering, majoring in Hydraulics and Water Resources Engineering at IIT Kanpur.Academically, I have spent about two years teaching as Assitant Professor atthe School of Civil Engineering, KIIT University and being a Project Scientist at Interdisciplinary Centre for Water Research (ICWaR) -IISc Bangalore."
  },
  {
    name: "Allysa Dallmann",
    image:process.env.PUBLIC_URL +"/images/Allysa.png",
    title: "Staff",
    bio: "Allysa has a background in Meteorology and Climate Dynamics. The core of her position is to work hand and hand with the UT City Climate CoLab to understand what are the needs in city departments and how we can provide information and climate data tools to them for their operations and local decision making here in Austin.",
    linkedin:"https://www.linkedin.com/in/allysa-dallmann/"
  },
  {
    name: "Ali Mousavinezhad ",
    image: process.env.PUBLIC_URL + "/images/Ali_Mousavinezhad_1.png",
    title: "PostDoctoral Researcher",
    bio: ""
  },
  {
    name: "Arya Chavoshi",
    image: process.env.PUBLIC_URL + '/images/Arya.png',
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Caleb Adams",
    image: process.env.PUBLIC_URL + '/images/Caleb.png',
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Dariya Fallon",
    image: process.env.PUBLIC_URL + "/images/defaultpic.png",
    title: "Staff",
    bio: ""
  },
  {
    name: "Dev Niyogi",
    image: process.env.PUBLIC_URL + "/images/Dev-Pic-Aug2024.jpg",
    title: "Principal Investigator (PI)",
    bio: "Professor and William Stamps Farish Chair, Department of Earth and Planetary Sciences, Jackson School of Geosciences, and Department of Civil, Architectural and Environmental Engineering, Cockrell School of Engineering, Affiliate Faculty: Oden Institute for Computational Engineering and Sciences, and UT Center for Space Research, The University of Texas at Austin",
    linkedin:"https://www.linkedin.com/in/texus",
    website: "https://niyogi.dev",
    email:"dev.niyogi@jsg.utexas.edu"
  },
  {
    name: "Harsh Kamath",
    image: process.env.PUBLIC_URL + '/images/Harsh.png',
    title: "Graduate Student",
    bio: "I am a PhD student working on urban meteorology. My primary focus is urban heat, urban dataset generation using AI/ML methods, development of urban parametrizations for offline and weather models. I also have a keen interest in urban boundary layer processes.",
    linkedin:"https://www.linkedin.com/in/harshkamath",
    email:"harsh.kamath@utexas.edu"
  },
  {
    name: "Hassan Dashtian",
    image:process.env.PUBLIC_URL +"/images/Hassan.jpeg",
    title: "Research Assistant Professor",
    bio: ""
  },
  {
    name: "Koorosh Azizi",
    image: process.env.PUBLIC_URL + "/images/Koorosh_2.png",
    title: "PostDoctoral Researcher",
    bio: ""
  },
  {
    name: "Manmeet Singh",
    image: process.env.PUBLIC_URL + "/images/manmeet.png",
    title: "PostDoctoral Researcher",
    bio: ""
  },
  {
    name: "Naveen Sudharshan",
    image: process.env.PUBLIC_URL +"/images/naveen.png",
    title: "PostDoctoral Researcher",
    bio: ""
  },
  {
    name: "Parika Grover",
    image:process.env.PUBLIC_URL +"/images/Parika.jpg",
    title: "Staff",
    bio: ""
  },
  {
    name: "Sasanka Talukdar",
    image: process.env.PUBLIC_URL + '/images/Sasanka.png',
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Shyama Mohanty",
    image: process.env.PUBLIC_URL + "/images/Shyama_1.png",
    title: "PostDoctoral Researcher",
    bio: ""
  },
  {
    name: "Ting-Yu Dai",
    image: process.env.PUBLIC_URL + '/images/Ting-Yu.png',
    title: "Graduate Student",
    bio: ""
  },
  {
    name: "Trevor Brooks",
    image: process.env.PUBLIC_URL + '/images/Trevor.png',
    title: "Graduate Student",
    bio: ""
  },   
  {
    name: "Xinxin Sui",
    image: process.env.PUBLIC_URL + '/images/Sui_2.png',
    title: "Graduate Student",
    bio: ""
  },
  // Add more postdoc members here
];